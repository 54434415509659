import { Fragment, useState } from 'react';
import {
    Create, SimpleForm, TextInput,
    useTranslate, Toolbar, SaveButton, ReferenceInput,
    NumberInput, ArrayInput, SimpleFormIterator, Button,
    AutocompleteInput, useNotify, useRedirect, useRefresh,
    useRecordContext, FormDataConsumer, useGetOne, minLength, maxLength,
    SelectInput,
} from 'react-admin';
import { makeStyles } from '@mui/styles';
import { Dns, Description, RssFeed, Memory, Add, Storage, Image } from '@material-ui/icons';
import { Dialog, DialogContent, DialogTitle, Box } from '@material-ui/core';
import { HandleError, SectionField, ViewTitle } from '../../components';
import { Cancel } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import convertByte from '../../utils/convertByte';
import DiskBusHelpModal from '../../components/diskBusHelp';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        display: 'flex',
    },
    spacer: {
        flex: 1,
    },
    buttonMarginLeft: {
        marginLeft: 10
    },
    flexBox: {
        width: 'auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    inputMarginLeft: {
        marginLeft: 20
    },
    border: {
        border: '1px solid #6b489d'
    },
    description: {
        '& .MuiInputBase-root': {
            paddingBottom: '50px',  // Adjust padding inside the input box
            paddingRight: '14px',  // Adjust padding inside the input box
            boxSizing: 'content-box',
        },
    }
}));

const Validate = (values) => {
    const errors = {};
    if (!values.name) errors.name = 'ra.validation.required';
    if (!values.pod) errors.pod = 'ra.validation.required';
    return errors;
};

const validateName = [minLength(2), maxLength(40)];

const VmCreate = ({ permissions, ...props }) => {
    const classes = useStyles();
    const translate = useTranslate();
    const [showDialog, setShowDialog] = useState(false);
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();

    const optionRenderer = choice => `${choice.name}`;
    const filterToQuery = searchText => ({ name: `${searchText}` });
    
    const onSuccess = (data) => {
        notify(translate('app.vms.create.successMessage'), { type: 'success' });
        redirect('show', '/vms', data.id);
        refresh();
    };

    const onError = (error) => {

        if (error.body) {
            {
                error.body.detail ? notify(`${error.body.message} : ${error.body.detail.errorMessage}`, { type: 'error' }) :
                    notify(`${error.body.message}`, { type: 'error' })
            };
        }
        else {
            notify('failed', { type: 'failed' });
        }
    };

    const podId = props.pod.id;
    const podName = props.pod.podName;

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const postDefaultValue = () => ({ pod: podId, visibility: ['admin', 'designer', 'trainer', 'trainee'] });

    const CreateToolbar = (props) => {
        const classes = useStyles();
        const translate = useTranslate();
        return (
            <Toolbar className={classes.toolbar}>
                <Button label="ra.action.cancel" size="medium" onClick={handleCloseClick} className={classes.border} >
                    <Cancel />
                </Button>
                <SaveButton label={translate('app.buttons.create')} className={classes.buttonMarginLeft} />
            </Toolbar>
        );
    };

    const FlavorField = () => {
        const record = useRecordContext();
        return (
            <Tooltip title={<div> vcpus:{record.vcpus} ram:{record.ram} </div>}>
                <span> <strong> {record.name} </strong> <div> vcpus:{record.vcpus} ram: {convertByte(record.ram, 2, "MB")}  </div> </span>
            </Tooltip >
        );
    };

    const inputText = choice => `${choice.name}`;
    const matchSuggestion = (filter, choice) => {
        return (
            choice.name.toLowerCase().includes(filter.toLowerCase())
        );
    };

    const GetImage = (imageId) => {
        const { data: imageData, isLoading, error } = useGetOne('images', { id: imageId });
        if (isLoading) {
            return <div>{'app.LoadingMessage'}</div>;
        };

        if (imageData.disks[0].format === 'qcow2' || imageData.disks[0].format === 'vmdk' || imageData.disks[0].format === 'raw') {
            let virtualSize = convertByte(imageData.disks[0].virtualSize, 'GB');
            virtualSize = parseInt(virtualSize.replace('GB', ''));

            return (
                <>
                    <SectionField label={translate('app.vms.create.disk')} icon={Storage} />
                    <NumberInput source='volumeSize' min='0' defaultValue={virtualSize} label={translate('volume (GB)')} />
                    <Box className={classes.flexBox}>
                        <AutocompleteInput source='busType' choices={[
                            { id: 'virtio', name: 'virtio' },
                            { id: 'scsi', name: 'scsi' },
                            { id: 'ide', name: 'ide' },
                        ]} defaultValue='virtio' />
                        <DiskBusHelpModal /> {/* Add the help button next to the AutocompleteInput */}
                    </Box>
                </>
            )
        }
        else {
            return (
                <>
                    <SectionField label={translate('app.vms.create.disk')} icon={Storage} />

                    <ArrayInput source='emptyVolume' label={translate('app.vms.create.emptyVolumes')}>
                        <SimpleFormIterator getItemLabel={index => `#${index + 1}`} limit={3}>
                            <NumberInput label='Size' source='size' min='0' />
                            <Box className={classes.flexBox}>
                                <AutocompleteInput source='busType' choices={[
                                    { id: 'virtio', name: 'virtio' },
                                    { id: 'scsi', name: 'scsi' },
                                    { id: 'ide', name: 'ide' },
                                ]} defaultValue='virtio' />
                                <DiskBusHelpModal /> {/* Add the help button next to the AutocompleteInput */}
                            </Box>
                        </SimpleFormIterator>
                    </ArrayInput>
                </>)
        }
    };

    return (
        <Fragment>
            <Button label={translate('app.vms.create.button')} onClick={handleClick}><Add /></Button>
            <Dialog
                fullWidth
                maxWidth="md"
                open={showDialog}
                onClose={handleCloseClick}
                aria-label={translate('app.vms.create.title')}
            >
                <DialogTitle><ViewTitle label={translate('app.vms.create.title')} icon={Dns} /></DialogTitle>
                <DialogContent>
                    <Create resource='vms' mutationOptions={{ onSuccess: onSuccess, onError }}>
                        <SimpleForm validate={Validate} toolbar={<CreateToolbar />} defaultValues={postDefaultValue}>
                            <AutocompleteInput source="pod" choices={[{ id: podId, name: podName }]} optionText="name" />
                            <SectionField label={translate('app.vms.create.data')} icon={Description} />
                            <Box className={classes.flexBox}>
                                <TextInput variant='outlined' source='name' label={translate('app.pods.name')} style={{ minWidth: 250 }} validate={validateName} />
                            </Box>
                            <Box className={classes.flexBox}>
                                <TextInput variant='outlined' source='description' className={classes.description} style={{ minWidth: 250 }} multiline />
                            </Box>

                            <SectionField label={translate('app.vms.create.network')} icon={RssFeed} />
                            <Box className={classes.flexBox}>

                                <ReferenceInput
                                    source="network"
                                    reference="networks"
                                    filter={{ pod: podId }}
                                >
                                    <AutocompleteInput optionText="name" source="network" label={translate('app.pods.network')} />
                                </ReferenceInput>
                                <TextInput variant='outlined' source='ipAddress' className={classes.inputMarginLeft} label={translate('app.pods.ipAddress')} />

                            </Box>

                            <SectionField label={translate('app.vms.create.resource')} icon={Memory} />
                            <Box className={classes.flexBox}>
                                <ReferenceInput
                                    source="flavor"
                                    reference="flavors"
                                >
                                    <AutocompleteInput optionText={<FlavorField />} label={translate('app.vms.create.flavor')}
                                        inputText={inputText} matchSuggestion={matchSuggestion} />
                                </ReferenceInput>

                                <AutocompleteInput variant='outlined' source='guest_OS' choices={[
                                    { id: 'linux', name: 'linux' },
                                    { id: 'windows', name: 'windows' },
                                    { id: 'other', name: 'other' },
                                ]} defaultValue='linux' className={classes.inputMarginLeft} />

                                <SelectInput variant='outlined' source='language' choices={[
                                    { id: 'en', name: 'app.users.languages.en' },
                                    { id: 'fr', name: 'app.users.languages.fr' },
                                ]} defaultValue='en' className={classes.inputMarginLeft} />

                            </Box>

                            <SectionField label={translate('app.vms.create.mediadevice')} icon={Image} />
                            <Box>
                                <>
                                    <>
                                        <ReferenceInput
                                            source="imageRef"
                                            reference="images"
                                            filter={{ "type": "base" }}
                                        >
                                            <AutocompleteInput
                                                source='name'
                                                optionText={optionRenderer}
                                                className={classes.templateInput}
                                                filterToQuery={filterToQuery}
                                                label={translate('app.vms.create.image')}
                                            />
                                        </ReferenceInput>

                                        <FormDataConsumer>
                                            {({
                                                formData,
                                                ...rest
                                            }) =>
                                                formData && formData.imageRef ? (
                                                    GetImage(formData.imageRef)
                                                ) :
                                                    <>
                                                        <SectionField label={translate('app.vms.create.disk')} icon={Storage} />

                                                        <ArrayInput source='emptyVolume' label={translate('app.vms.create.emptyVolumes')}>
                                                            <SimpleFormIterator inline getItemLabel={index => `#${index + 1}`} limit={3}>
                                                                <NumberInput label='Size' source='size' min='0' />
                                                                <Box className={classes.flexBox}>
                                                                    <AutocompleteInput source='busType' choices={[
                                                                        { id: 'virtio', name: 'virtio' },
                                                                        { id: 'scsi', name: 'scsi' },
                                                                        { id: 'ide', name: 'ide' },
                                                                    ]} defaultValue='virtio' />
                                                                    <DiskBusHelpModal /> {/* Add the help button next to the AutocompleteInput */}
                                                                </Box>
                                                            </SimpleFormIterator>
                                                        </ArrayInput>
                                                    </>
                                            }
                                        </FormDataConsumer>
                                    </>
                                </>
                            </Box>
                        </SimpleForm>
                    </Create>
                </DialogContent>
            </Dialog>
        </Fragment>
    );
};

export default VmCreate;
